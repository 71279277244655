<template>
    <div class="certification px-sm-2"> 
        <img :src="require('@/assets/' + image)" :width="imgWidth" :height="imgHeight">
    </div>
</template>

<script>
export default {
    name: 'Certification',
    props: {
        image: String,
        imgWidth: String, 
        imgHeight: String,
    },
}
</script>
